import { useQuery } from "@vue/apollo-composable";
import { PropertyBudgetRecordsDocument } from "~/graphql/generated/graphql";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
export var usePropertyBudgetRecordsQuery = function usePropertyBudgetRecordsQuery(ids) {
  var preferences = usePreferencesNamespace("preferencesNamespacePortfolioPropertyBalance");
  var _useQueryFilters = useQueryFilters(),
    queryFilters = _useQueryFilters.value,
    setQueryFilters = _useQueryFilters.setValue;
  setQueryFilters("filters", function () {
    return {
      matches: [{
        id: [Operator.In, ids.value]
      }]
    };
  });
  var query = useQuery(PropertyBudgetRecordsDocument, function () {
    return {
      filters: queryFilters.value.filters
    };
  }, function () {
    var _preferences$result, _preferences$result$v;
    var enabled = !!((_preferences$result = preferences.result) !== null && _preferences$result !== void 0 && (_preferences$result$v = _preferences$result.value) !== null && _preferences$result$v !== void 0 && _preferences$result$v.showBudgets);
    return {
      enabled: enabled
    };
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  var result = computed(function () {
    var _query$result$value$a, _query$result$value, _query$result$value$a2;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : (_query$result$value$a2 = _query$result$value.assetManagementBudgets) === null || _query$result$value$a2 === void 0 ? void 0 : _query$result$value$a2.items) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  return {
    loading: loading,
    result: result
  };
};